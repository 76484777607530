import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';

import editIcon from '../../images/editBlack.png';
import plusIcon from '../../images/editPlusBlack.png';
import searchButton from '../../images/searchBlack.png';

import AddTables from './addTables';

class Admin extends Component{ 
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            eventObjects: [],
            imageUrls: {},
            search: '', // State variable for the search term
        }

        this.showPopup = this.showPopup.bind(this);
    }
    async componentDidMount(){
        const {session} = this.props;
        await this.props.findEvents(null, session.master, session.email);
        this.fetchEventLogos();
    }

    componentDidUpdate(prevProps) {
        const { Events } = this.props.lookup;
    
        if (Events && Events.length > 0 && Events !== prevProps.lookup.Events) {
            this.fetchEventLogos(); 
        }
    }

    showPopup() {
        this.setState({showModal: !this.state.showModal});
    }

    create() {
        const {session} = this.props;
        this.props.CreateEvent(session.email);
    }

    addButton(){
        return(
            <Fragment>
                    <button className="overlayButton" onClick={() => this.create()}>Create New Event</button>
            </Fragment>
        )
    }

    async fetchEventLogos() {
        const {Events} = this.props.lookup
        let imageUrls = {};

        for (let element of Events) {
            if (element.logo_url) {
                let type = "events";
                let id = element.id;
                const url = await this.props.getLogo({ type, id });
                imageUrls[element.id] = url;
            }
        }
        this.setState({ imageUrls });
    }

    goToEvent(id) {
        userActions.reroute("admin/event/" + id)
    }

    editEvent(id) {
        //We are reusing the add page to edit as well
        userActions.reroute("admin/editevent/" + id)
    }

    editEventJudges(id) {
        //We are reusing the add page to edit as well
        userActions.reroute("admin/assignjudges/" + id)
    }

    updatePublish(eid)
    {   
        const {session} = this.props
        this.props.attemptPublish(eid, session)
    }

    updateEventSearch = (event) => {
        const searchValue = event.target.value;
        this.setState({
          search: searchValue,
        });

        // Filter the JudgeData based on the search term
        const { Events } = this.props.lookup;
        const filteredEventInfo = Events.filter((element) => {
            
          return (
            element.event_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            element.round.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
      
        // If the search term is empty, use the original JudgeData
        const eventObjects = searchValue ? filteredEventInfo : Events;
        //console.log(filteredEventInfo)
        this.setState({
            eventObjects: eventObjects,
        });
      };

    draftEventSquare(){
        const {Events} = this.props.lookup
        const dataToRender = this.state.eventObjects.length > 0 ? this.state.eventObjects : Events;
        const { search } = this.state;
        const draftInfo = dataToRender.map(
            (element) => {
                if(element.is_published == 0) {
                    const logoUrl = this.state.imageUrls[element.id];
                    return(
                        <Fragment>
                            <div className='col-2 fourSquare'>
                                <div className=''>
                                    <div className = {element.event_name.length >= 11 ? "textBox" : "altTextBox"}>
                                        <span><h2>{element.event_name ? element.event_name : 'Undefined'}</h2></span>
                                    </div>
                                    <div className = {element.company.length >= 11 ? "textBox" : "altTextBox"}>
                                        <span><h5>Host: {element.company ? element.company : 'Undefined'}</h5></span>
                                    </div>
                                    <p className='event-logo-text'>{element.round ? element.round : 'Undefined Round'}</p>
                                    {logoUrl ? <img src={logoUrl} className='event-logo' /> : <p className='event-logo-text'>No Image</p>}
                                    <br/><br/>
                                    <div className='row fourSquareBarrier'>
                                        <div className='col-6'>
                                            <button className='blueButtonNormal' onClick={() => this.updatePublish(element.id)}>Publish</button>
                                        </div>
                                        <div className='col-2'></div>
                                        <div className='col-4'>
                                            <button className="editButton" onClick={() => this.editEvent(element.id)}><img src={editIcon} alt="edit button png"/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            }
        )
        return(
            <Fragment>{draftInfo}</Fragment>
        )
    }

    liveEventSquares(){
        const {Events} = this.props.lookup
        const dataToRender = this.state.eventObjects.length > 0 ? this.state.eventObjects : Events;
        const { search } = this.state;
        const liveInfo = dataToRender.map(
            (element) => {
                if(element.is_published == 1) {
                    const logoUrl = this.state.imageUrls[element.id];
                    return(
                        <Fragment>
                            <div className='col-2 fourSquare'>
                                <div className=''>
                                    <div className = {element.event_name.length >= 11 ? "textBox" : "altTextBox"}>
                                        <span><h2>{element.event_name ? element.event_name: 'Undefined'}</h2></span>
                                    </div>
                                    <div className = {element.company.length >= 11 ? "textBox" : "altTextBox"}>
                                        <span><h5>Host: {element.company ? element.company : 'Undefined'}</h5></span>
                                    </div>
                                    <p className='event-logo-text'>{element.round ? element.round : 'Undefined Round'}</p>
                                    {logoUrl ? <img src={logoUrl} className='event-logo' /> : <p className='event-logo-text'>No Image</p>}
                                    <br/><br/>
                                    <div className='row fourSquareBarrier'>
                                        <div className='col-6'>
                                            <button className='blueButtonNormal' onClick={()=>this.goToEvent(element.id)}>View</button>
                                        </div>
                                        <div className='col-2'></div>
                                        <div className='col-4'>
                                            <button className="editButton" onClick={() => this.editEventJudges(element.id)}><img src={plusIcon} alt="edit button png"/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>  
                    )
                }
            }
        )
        return(
            <Fragment>{liveInfo}</Fragment>
        )
    }
    

    render(){
        const { search } = this.state;
        const { master } = this.props.session;

        return(
            <Fragment>
                <div className="container d-flex">
                    <div className="ps-5">
                        <h1>Admin Dashboard</h1>
                    </div>
                    {master == 1 ?
                        <div className="ps-3">
                            <button className="logout-btn" onClick={() => userActions.reroute("admin/accountdash/")}>View Admin Accounts</button>
                        </div>
                        :
                        <span></span>
                    }

                </div>
                <nav>
                <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-edit-tab" data-bs-toggle="tab" data-bs-target="#nav-edit" type="button" role="tab" aria-controls="nav-edit" aria-selected="true">Edit Events</button>
                    <button className="nav-link" id="nav-live-tab" data-bs-toggle="tab" data-bs-target="#nav-live" type="button" role="tab" aria-controls="nav-live" aria-selected="false">Live Events</button>
                </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">

                    <div className="tab-pane fade show active" id="nav-edit" role="tabpanel" aria-labelledby="nav-edit-tab">
                        <br/>
                        <div className="search-box rightSearchBoxAdmin">
                            <button className="btn-search"><img src={searchButton} alt='search button png'/></button>
                            <input type="text" className="input-search" placeholder="Type to Search..." value={search} onChange={() => this.updateEventSearch}/>
                        </div><br/>
                        <div className='container settingFourSquare'>
                            <div className='row'>
                                {this.draftEventSquare()}
                            </div>
                        </div>
                        {this.addButton()}
                    </div>

                    <div className="tab-pane fade" id="nav-live" role="tabpanel" aria-labelledby="nav-live-tab">
                        <br/>
                        <div className="search-box rightSearchBoxAdmin">
                            <button className="btn-search"><img src={searchButton} alt='search button png'/></button>
                            <input type="text" className="input-search" placeholder="Type to Search..." value={search} onChange={this.updateEventSearch}/>
                        </div><br/>
                        <div className='container settingFourSquare'>
                            <div className='row'>
                                {this.liveEventSquares()}
                            </div>
                        </div>
                        {this.addButton()}                
                    </div>
                    
                </div>
            </Fragment>
        );
    }
 }

 function mapState(state) {
    const {session, lookup} = state;
    return {session, lookup};
}

const actionCreators = {
    findEvents: userActions.GetEvents,
    CreateEvent: userActions.CreateEvent,
    mapJudgeTrack: userActions.mapJudgeTrack,
    attemptPublish: userActions.AttemptPublish,
    getLogo: userActions.getLogo,
};

export default connect(mapState, actionCreators)(Admin);