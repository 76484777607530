export const userConstants = {
    REQUEST: "REQUEST",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    testSuccess: "You Finally Figured It Out!",
    LOGIN: 'Logging In',
    LOGINFAILURE: 'Failed to Log in',
    LOGOUT: 'Logging Out',
    GETACCOUNTINFO: 'Account Information Found!',
    GETADMINS: 'Grabbed Admins Information',
    GETADMIN: 'Grabbed Admin Information',
    GETTEAMS: 'Grabbed Team Information',
    GETCOMMENTS: 'Grabbed Comments',
    GETSCORES: 'Grabbed Scores',
    GETJUDGES: 'Grabbed Judges',
    GETQUESTIONS: 'Grabbed Questions',
    GETEVENTS: 'Grabbed Events',
    GETLIVEJUDGEINFO: 'Grabbed Live Judge Data',
    GETLIVETEAMINFO: 'Grabbed Live Team Data',
    GETMYTEAMINFO: 'Grabbed My Team Info',
    CREATEEVENT: 'Created Event',
    GETRESULTS: 'Results Grabbed',
    GETTRACKS: 'Grabbed Tracks in Event',
}

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};