import React, {Component, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
//import Judges from './pages';
//import About from './pages/about';
//import Blogs from './pages/blogs';

//Anytime you add pages that have additional things with them besides the class you have to export them differently
import Home from '../pages/home';
import PageNotFound from '../pages/notfound';

import Admin from '../pages/admin/adminhome.js';
import AddEvent from '../pages/admin/AddEvent.js';
import CreateAdmin from '../pages/admin/CreateAdmin.js';
import EditAdmin from '../pages/admin/EditAdmin.js';
import AccountDash from '../pages/admin/AccountDashboards.js';
import AddTables from '../pages/admin/addTables.js';
import AssignJudges from '../pages/admin/AssignJudges.js';
import EditEvent from '../pages/admin/EditEvent.js';

//import ViewEvent from '../pages/admin/ViewEvent.js';
import Event from '../pages/admin/EventScreen.js';
import SignIn from '../pages/admin/SignIn.js';


import JudgeDiscussion from '../pages/judges/judgeDiscussion';

import Judges from '../pages/judges/judges.js';
import SignInJudge from '../pages/judges/SignInJudge.js';

import {NavBar} from '../Components';
import { userActions } from '../actions/useractions';

class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {clearance} = this.props.session

    return (
      <Fragment>
        <NavBar/>
        <BrowserRouter>
            <Routes>
                <Route exact path='/' 
                element={
                  clearance === 'judge' ? <Judges/> 
                  : clearance === 'admin' ? <Admin />
                  : <Home />
                  } 
                />
                

                <Route path='/signin' element={<SignIn />} />
                <Route path='/signinjudge' element={<SignInJudge />} />
                {clearance === "judge" ? 
                  <Route path='/judges' element={<Judges />} /> 
                : null}
                {clearance === "admin" ? 
                  <Fragment>
                    <Route path='/admin/event/:id' element={<LiveEventWrapper />} />
                    <Route path='/admin/createadmin/' element={<CreateAdmin />} />
                    <Route path='/admin/editadmin/:id' element={<EditAdminAccount />} />
                    <Route path='/admin/accountdash/' element={<AccountDash />} />
                    <Route path='/admin/discussion/:id' element={<LiveDiscussionWrapper/>} />
                    <Route path='/admin/addevent/' element={<AddEvent />} />
                    <Route path='/admin/editevent/:id' element={<EditEventWrapper />} />
                    <Route path='/admin/assignjudges/:id' element={<AssignJudgesWrapper />} />  
                    <Route path='/admin/dashboard' element={<Admin />} />
                    <Route path='/admin/signin' element={<SignIn />} />
                  </Fragment>
                : null}
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
      </Fragment>
    );
  }
}
function EditEventWrapper() {
  let { id } = useParams();
  return <EditEvent id={id}/>
}

function LiveEventWrapper() {
  let { id } = useParams();
  return <Event id={id}/>
}

function EditAdminAccount() {
  let { id } = useParams();
  return <EditAdmin id={id}/>
}

function LiveDiscussionWrapper() {
  let { id } = useParams();
  return <JudgeDiscussion id={id}/>
}
function AssignJudgesWrapper() {
  let { id } = useParams();
  console.log(id)
  return <AssignJudges id={id}/>
}
function mapState(state) {
  const {session} = state;
  return {session};
}

const actionCreators = {
};

export default connect(mapState, actionCreators)(App);