import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { userActions } from '../../actions/useractions';

import loginPicture from '../../images/nightBuilding.png';

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            error: false
        }

        this.signIn = this.signIn.bind(this)
    }

    signIn(e) {
        e.preventDefault();
        const {email, password} = this.state;
        this.props.LoginAdmin(email, password, (err) => {
            if (err) {
                this.setState({error: true})
            }
            else {
                this.setState({error: false})
            }
        });
    }

    render() {
        return (
            <Fragment>
                <center>
                    <div className='container'>
                        <div className='row justify-content-around'>
                            <div className='col-6 split signInBox'>
                                <form className="" onSubmit={this.signIn}>
                                <h1 className='textMarginTop textMarginBottom'>Admin Sign In</h1>
                                <div>
                                    <input id='username' className='inputRounded' placeholder="Enter Email" type="email" onChange={(e) => this.setState({email: e.target.value})} required/>
                                </div>
                                <br/>
                                <div>
                                    <input className='inputRounded' placeholder="Enter Password" type="password" onChange={(e) => this.setState({password: e.target.value})} required/>
                                    {this.state.error ? <p className='error-message'><br/>Incorrect password or email</p> : <></>}
                                </div>
                                <br/>
                                <button className='blueSubmitButton'>Submit</button>
                                </form>
                            </div>
                            <div className='col-6 split informationBox' style={{backgroundImage: `url(${loginPicture})`, backgroundSize:"auto 100%", backgroundRepeat: "no-repeat", backgroundPosition:"46% 100%"}}>
                                <div className='border'></div>
                                <p className='textInstructions'><a href='/SignInJudge' className='noLinkDecorWhiteFont'>If you are a judge, please reference the judge login which can be accessed here</a>.</p>
                            </div>
                        </div>
                    </div>
                </center>
            </Fragment>
        );
    }
}

const actionCreators = {
    LoginAdmin: userActions.LoginAdmin
};

function mapState(state) {
    const {session, lookup} = state;
    return {session, lookup};
  }

export default connect(mapState, actionCreators) (SignIn);