import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';

import searchButton from '../../images/searchBlack.png';

class ViewEvent extends Component{
    constructor(props){
        super(props);
        this.state ={
            judgeObjects: [],
            teamObjects: [],
            eventObjects: [],
            sortColumn: '', // To keep track of the column being sorted
            sortOrder: 1,   // 1 for ascending, -1 for descending
            showModal: false,
            beenPressed: false,
            search: '', // State variable for the search term
        }
    }

    componentDidMount(){
        this.props.findLiveJudgeData(this.props.id); 
        this.props.findLiveTeamData(this.props.id);
        this.props.findEvents();
    }

    RenderJudgeObjects()
    {
        const {JudgeData} = this.props.lookup
        const { sortColumn, sortOrder } = this.state;

        const dataToRender = this.state.judgeObjects.length > 0 ? this.state.judgeObjects : JudgeData;


        if(dataToRender.length > 0){
            const displayJudgeData = dataToRender.map(
                (element) => {
                    return(
                        <Fragment>
                            <tr>
                                <td>{element.judge_name}</td>
                                <td>{element.team_name}</td>
                                <td>{element.avg_score != null ? element.avg_score : "N/A"}</td>
                            </tr>
                        </Fragment>
                    )
                })
            return(
                <Fragment>{displayJudgeData}</Fragment>
            )
        }
        else
        {
            return(
                <Fragment><p>Loading</p></Fragment>
            )
        }
        
    }

    goToEvent(id) {
        userActions.reroute("admin/discussion/" + id)
    }
    flipModal() {
        this.setState((prevState) => ({
          showModal: !prevState.showModal,
        }));
    }

    codesModal() {
        const {JudgeData} = this.props.lookup

        const uniqueCodes = JudgeData.reduce((acc, judgeObj) => {
            const { code } = judgeObj;
            // Check if the number property already exists in the accumulator object and add if not
            if (!acc[code]) {
                acc[code] = true;
                acc.result.push(judgeObj);
            }
            return acc;
        }, { result: [] }).result;

        console.log(uniqueCodes)
        const judges = uniqueCodes.map(
            (element) => {
                return(
                    <Fragment>
                        <tbody>
                            <tr>
                                <td>{element.judge_name}</td>
                                <td>{element.code}</td>
                            </tr>
                        </tbody>
                    </Fragment>
                )
            })
        return(
            <Fragment>
                <table className='judge-code-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                        </tr>
                    </thead>
                    {judges}
                </table>
            </Fragment>
        )
    }

    LiveEventHeader(){
        
                return(
                    <Fragment>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-3'><h1>Live Event Panel</h1></div>
                                <div className='col-5'>
                                    <a href="/admin/dashboard"><button className='logout-btn'>Back to Dashboard</button></a>
                                </div>
                                <div className='col-2'><button className='reveal-schools-btn view-codes' onClick={()=>this.flipModal()}>View Codes</button></div>
                                <div className='col-2'><button className='reveal-schools-btn' onClick={()=>this.goToEvent(this.props.id)}>Judge Discussions</button></div>
                            </div>
                        </div>
                    </Fragment>
                )

    }


    LoadTeamData(){
        const {TeamData} = this.props.lookup
        const { sortColumn, sortOrder } = this.state;

        const dataToRender = this.state.teamObjects.length > 0 ? this.state.teamObjects : TeamData;

        const teamData = dataToRender.map(
            (element) => {
                return(
                    <Fragment>
                        <tr>
                            <td>{element.team_name}</td>
                            <td>{element.school}</td>
                            <td>{element.track}</td>
                            <td>{element.score != null ? element.score : "No Score Given"}</td>
                        </tr>
                    </Fragment>
                )
            })
        return(
            <Fragment>{teamData}</Fragment>
        )
    }

    resetSort = () => {
        this.setState({
            sortColumn: '',
            sortOrder: 1, // Reverse the sortOrder for next sorting action
          });
    }

    sortJudgeData = (column) => {
        const { JudgeData } = this.props.lookup;
        const { sortOrder } = this.state;
        const { beenPressed } = this.state;
      
        JudgeData.sort((a, b) => {
          if (a[column] < b[column]) return -sortOrder;
          if (a[column] > b[column]) return sortOrder;
          return 0;
        });
      
        this.setState({
          judgeObjects: JudgeData,
          sortColumn: column,
          sortOrder: -sortOrder, // Reverse the sortOrder for next sorting action
          beenPressed: true,
        });
    };

    sortTeamData = (column) => {
        const { TeamData } = this.props.lookup;
        const { sortOrder } = this.state;
      
        TeamData.sort((a, b) => {
          if (a[column] < b[column]) return -sortOrder;
          if (a[column] > b[column]) return sortOrder;
          return 0;
        });
      
        this.setState({
          teamObjects: TeamData,
          sortColumn: column,
          sortOrder: -sortOrder, // Reverse the sortOrder for next sorting action
        });
    };

    getSortIcon(columnName) {
        const { sortColumn, sortOrder } = this.state;
        if (sortColumn === columnName) {
          return sortOrder === 1 ? '↓' : '↑';
        }
        return '';
      } 

      updateJudgeSearch = (event) => {
        const searchValue = event.target.value;
        this.setState({
          search: searchValue,
        });
      
        // Filter the JudgeData based on the search term
        const { JudgeData } = this.props.lookup;
        const filteredJudgeData = JudgeData.filter((element) => {
          return (
            element.judge_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            element.team_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            (element.avg_score && element.avg_score.toString().includes(searchValue))
          );
        });
      
        // If the search term is empty, use the original JudgeData
        const judgeObjects = searchValue ? filteredJudgeData : JudgeData;
      
        // Apply the sorting (if any) to the filtered data
        if (this.state.sortColumn) {
          judgeObjects.sort((a, b) => {
            // Sorting logic (similar to the previous sorting function)
          });
        }
      
        this.setState({
          judgeObjects: judgeObjects,
        });
      };

      updateTeamSearch = (event) => {
        const searchValue = event.target.value;
        this.setState({
          search: searchValue,
        });
      
        // Filter the JudgeData based on the search term
        const { TeamData } = this.props.lookup;
        const filteredTeamData = TeamData.filter((element) => {
          return (
            element.team_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            element.school.toLowerCase().includes(searchValue.toLowerCase()) ||
            element.track.toLowerCase().includes(searchValue.toLowerCase()) ||
            (element.avg_score && element.avg_score.toString().includes(searchValue))
          );
        });
      
        // If the search term is empty, use the original JudgeData
        const teamObjects = searchValue ? filteredTeamData : TeamData;
      
        // Apply the sorting (if any) to the filtered data
        if (this.state.sortColumn) {
          teamObjects.sort((a, b) => {
            // Sorting logic (similar to the previous sorting function)
          });
        }
      
        this.setState({
          teamObjects: teamObjects,
        });
      };

    render() { 
        const { searchValue } = this.state;

        return(
            <Fragment>
                {this.LiveEventHeader()}

                { this.state.showModal &&
                    <div className="modal-main">
                        <button onClick={() => this.flipModal()}>X</button>
                        {this.codesModal()}
                    </div>                      
                }
                <nav>
                <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-judges-tab" data-bs-toggle="tab" data-bs-target="#nav-judges" type="button" role="tab" aria-controls="nav-judges" aria-selected="true">Judges</button>
                    <button className="nav-link" id="nav-teams-tab" data-bs-toggle="tab" data-bs-target="#nav-teams" type="button" role="tab" aria-controls="nav-teams" aria-selected="false">Teams</button>
                </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-judges" role="tabpanel" aria-labelledby="nav-judges-tab">
                        <br/>
                        <div className="search-box rightSearchBoxAdmin">
                            <button class="btn-search"><img src={searchButton} alt='search button png'/></button>
                            <input type="text" class="input-search" placeholder="Type to Search..." value={searchValue} onChange={this.updateJudgeSearch}/>
                        </div>
                        <center>
                            <div className='container'>
                                <table className='tableView'>
                                    <tr>
                                        <th onClick={() => this.sortJudgeData('judge_name')}>Judge {this.getSortIcon('judge_name')}</th>
                                        <th onClick={() => this.sortJudgeData('team_name')}>Team {this.getSortIcon('team_name')}</th>
                                        <th onClick={() => this.sortJudgeData('avg_score')}>Overall Score {this.getSortIcon('avg_score')}</th>
                                    </tr>
                                    {this.RenderJudgeObjects()}
                                </table>
                            </div>
                        </center>
                    </div>
                    <div className="tab-pane fade" id="nav-teams" role="tabpanel" aria-labelledby="nav-teams-tab">
                        <br/>
                        <div className="search-box rightSearchBoxAdmin">
                            <button className="btn-search"><img src={searchButton} alt='search button png'/></button>
                            <input type="text" className="input-search" placeholder="Type to Search..." value={searchValue} onChange={this.updateTeamSearch}/>
                        </div>
                        <center>
                            <div className='container'>
                                <table className='tableView'>
                                    <tr>
                                        <th onClick={() => this.sortTeamData('team_name')}>Team {this.getSortIcon('team_name')}</th>
                                        <th onClick={() => this.sortTeamData('school')}>School {this.getSortIcon('school')}</th>
                                        <th onClick={() => this.sortTeamData('track')}>Track {this.getSortIcon('track')}</th>
                                        <th onClick={() => this.sortTeamData('score')}>Overall Score {this.getSortIcon('score')}</th>
                                    </tr>
                                    {this.LoadTeamData()}
                                </table>
                            </div>
                        </center>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapState(state) {
    const {session, lookup} = state;
    return {session, lookup};
}

const actionCreators = {
    findLiveJudgeData: userActions.GetLiveJudgeInfo,
    findLiveTeamData: userActions.GetLiveTeamInfo,
    findEvents: userActions.GetEvents,

};

export default connect(mapState, actionCreators)(ViewEvent);