import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';
import DragDrop from './dragdrop';
import { current } from '@reduxjs/toolkit';

class judgeDiscuss extends Component{
    constructor(props){
        super(props);
        this.state ={    
            teams: [],        
            adminReview: false,
            tabData: [],
            event_ID: 0
            }
        this.downloadInfomation = this.downloadInfomation.bind(this);
        this.createCSV = this.createCSV.bind(this);
        this.MapTrackTeamData = this.MapTrackTeamData.bind(this);
        this.downloadAllTeams = this.downloadAllTeams.bind(this);

    }

    componentDidMount(){
        //this.props.getTeams()
        this.props.findTracks(this.props.id);
        this.props.findLiveTeamData(this.props.id);
        
    }

    componentDidUpdate() {
        const {TeamData} = this.props.lookup;
        if(this.props.lookup.CompetitionTracks && this.props.lookup.TeamData)
        {
          if(this.props.lookup.CompetitionTracks.length > 0 && this.props.lookup.TeamData.length > 0 && this.state.tabData.length === 0 )
            {
                this.MapTrackTeamData();
                this.setState({teams: TeamData})
            }
        }
    }

    LoadTabData(tabContent, adminReview, index) {
        return (
          <Fragment>
            <div className='team-list-container'>
              <DragDrop
                itemArr={tabContent}
                onListReorder={(updatedList) => this.handleListReorderIndex(updatedList, index)}
          
                property={'team'}
                adminReview={adminReview}
                onDownloadTeam={this.setupInformation}
              />
            </div>
          </Fragment>
        );
      }

    goToBack(id) {
        userActions.reroute("admin/event/" + id)
    }

    MapTrackTeamData()
    {
        const {CompetitionTracks, TeamData} = this.props.lookup
        const tabDataObjects = []

        TeamData.sort((a, b) => {
            const scoreA = a.score === null ? -Infinity : parseFloat(a.score);
            const scoreB = b.score === null ? -Infinity : parseFloat(b.score);
            
            return scoreB - scoreA; // Reverse order for descending sort
        });

        CompetitionTracks.map( (track) => {
            console.log(track.name)
            const tabContentData = {
                title: track.name,
                content: []
            }

            TeamData.map(
                (team_element) => {
                    if(track.name == team_element.track)
                    {
                        const teamContentData = {
                            team: team_element.team_name,
                            school: team_element.school,
                            score: team_element.score == null ? 'No Scores Given' : team_element.score,
                        }
                        tabContentData.content.push(teamContentData);
                    } 
                }
            )
            tabDataObjects.push(tabContentData);
        })

        this.setState({tabData: tabDataObjects})
    }
    
    handleListReorder = (updatedList) => {
        this.setState({ teams: updatedList }); 
    }

    handleListReorderIndex = (updatedList, tabIndex) => {
        const updatedTabData = [...this.state.tabData];
        updatedTabData[tabIndex].content = updatedList;
        this.setState({ tabData: updatedTabData });
    };

    /** Creates a list of the Team ID's and sends a request to the backend for all judge comments for those teams.
     * 
     */
    downloadAllTeams = ()  => {
        var teamList = this.state.teams;
        var teamIDs = [];
        for(var i = 0; i < teamList.length; i++) {
            //this.setupInformation(teamList[i].tid , teamList[i].team_name, 106);
            teamIDs.push(teamList[i].tid);
        }
        this.props.getResults(this.props.id, teamIDs);
        this.setupInformation();
    }

    /** Takes the judge comments from the reducer and sorts them into their individual teams to create a CSV file.
    */
    setupInformation = async () => {
        const {teams} = this.state
        var teamList = await this.props.lookup.ResultsData.results;
        if(teamList && teams) {
            teams.forEach(team => {
                var downloadTeam = [];
                teamList.forEach(teamEntry => {
                    if(teamEntry.tid == team.tid) {
                        downloadTeam.push({judge_name: teamEntry.judge_name, comment: teamEntry.comment, is_shareable: teamEntry.is_shareable});
                    }
                });
                if(downloadTeam != []) {
                    this.createCSV(team.team_name, downloadTeam);
                }
            });
        }
    }

    /**Takes the data from the formatted functions and adds headers so it can be turned into a csv file.
     * @param {String} teamName - name of the team to add to the file name
     */
    createCSV = async (teamName, teamData) => {
        //Grabs the data from the reducer
        var data = await teamData;
        console.log(data);
        //Creates headers for the formatted JSON data
        let headers =['Judge Name,Comments,Share Status'];
        //Takes the data and the headers and joins them together as one object
        let csv = data.reduce((acc, Results) => {
            const {judge_name , comment, is_shareable} = Results;
            console.log(comment);
            let is_share = 'No';
            if(is_shareable == 1) {
                is_share = 'Yes';
            }

            const escapeForCSV = (field) => {
                if (typeof field === 'string' && (field.includes(',') || field.includes('"') || field.includes('\n'))) {
                    return `"${field.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
                }
                return field;
            };

            acc.push([
                escapeForCSV(judge_name),
                escapeForCSV(comment),
                escapeForCSV(is_share)
            ].join(','))
            console.log(acc);
            return acc
        }, [])
        
        //Calls the downloadInformation function with the data and the teamname
        this.downloadInfomation({
            data: [...headers, ...csv].join('\n'),
            fileName: teamName.replace(' ', '')+'Comments.csv',
            fileType: 'text/csv',
        })
    }

    /**Takes the formatted data and creates a blob object that downloads onto the computer.
     * @param {*} data -formmatted data and headers
     * @param {*} fileName - file name for download
     * @param {*} fileType - type of file being downloaded
     */
    downloadInfomation({data, fileName, fileType}) {
        const blob = new Blob([data], {type: fileType})

        const a = document.createElement('a');
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
    }



    basicHeader(){
        return(
            <Fragment>
                <div className='col-12'>
                    <div className='row textMargin'>
                        <div className='col-6'>
                            <h2>Team Name</h2>
                        </div>
                        <div className='col-6 leftText'>
                            <h2 className='rightText'>Score:</h2>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    adminReview() {
        const {adminReview} = this.state;
        const flippedReview = !adminReview;
        this.setState({adminReview: flippedReview})
        this.setState({event_ID: this.props.id });
    }

    handleTabClick = (index) => {
        this.setState({
          activeTab: index,
        });
      };

    render(){
        
        /*console.log(this.props.lookup.TeamData.length === 0)
        if(this.props.lookup.TeamData.length === 0 && this.props.session)
        {
            this.props.findLiveTeamData(this.props.id);
        }
        else if(this.state.tabData.length === 0){
            console.log()
            this.MapTrackTeamData();
        }*/

        const {adminReview, teams} = this.state;
        const { activeTab } = this.state;
        const {TeamData} = this.props.lookup;

        return (
            <Fragment>
                <div>
                    <div className='container'>
                            <div className='row'>
                                <div className='col-5'>
                                    <h1 className='rightTextAdmin'>Team Discussions</h1>
                                </div>
                                <div className='col-5'>
                                    <a href="/admin/dashboard"><button className='logout-btn'>Back to Dashboard</button></a>
                                </div>
                                <div className='col-2'>
                                    <button className='reveal-schools-btn' onClick={() => this.adminReview()}>
                                        {adminReview ? 'Hide Schools' : 'Reveal Schools'}
                                    </button>
                                </div>
                            </div>
                        </div><br/>
                    </div>

                    <div className= "container">
                        <div className='row'>
                            <div className={adminReview ? "col-6" : 'col-12'}>
                                <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                                    {this.state.tabData.map((tab, index) => (
                                        <li className="nav-item" key={index}>
                                            <button
                                            className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                            onClick={() => this.handleTabClick(index)}
                                            >
                                            <h7 className="tabFix">{tab.title}</h7>
                                            </button>
                                        </li>
                                    ))}
                                </div>
                                <div className="tab-content">
                                <br/><div className='container'>
                                    {this.basicHeader()}
                                </div>
                                {this.state.tabData.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={`tab-pane ${activeTab === index ? 'active show fade' : ''}`}
                                    >
                                        {this.LoadTabData(tab.content, adminReview, index)}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Fragment>
                                <div className={adminReview ? 'col-6' : "hideDisplay"}>
                                    <nav>
                                        <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-final-tab" data-bs-toggle="tab" data-bs-target="#nav-final" type="button" role="tab" aria-controls="nav-final" aria-selected="true">Final Ratings</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-final" role="tabpanel" aria-labelledby="nav-final-tab">
                                        <br/>                      
                                        <div className='container '>
                                            {this.basicHeader()}  
                                            <button className='reveal-schools-btn downloadButton px-3' onClick={() => this.downloadAllTeams()}>Download Info</button>
                                                <div className='row'>
                                                <div className='team-list-container'>
                                                    <DragDrop
                                                        itemArr={teams}
                                                        onListReorder={this.handleListReorder}
                                                        property={'team_name'}
                                                        adminReview={adminReview}
                                                    />
                                                    {console.log(teams)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                    </div>
                </div>
            </Fragment>
          );
    }
 }

function mapState(state) {
    const {session, lookup} = state;
    return {session, lookup};
}

/**
 * Sorts a HTML table.
 * 
 * @param {HTMLTableElement} table The table to sort
 * @param {number} column The index of the column to sort
 * @param {boolean} asc Determines if the sorting will be in ascending
 */


function sortTableByColumn(table, column, asc = true) {
    const dirModifier = asc ? 1 : -1;
    const tBody = table.tBodies[0];
    const rows = Array.from(tBody.querySelectorAll("tr"));

    // Sort each row
    const sortedRows = rows.sort((a, b) => {
        const aColText = a.querySelector(`td:nth-child(${ column + 1 })`).textContent.trim();
        const bColText = b.querySelector(`td:nth-child(${ column + 1 })`).textContent.trim();

        return aColText > bColText ? (1 * dirModifier) : (-1 * dirModifier);
    });

    // Remove all existing TRs from the table
    while (tBody.firstChild) {
        tBody.removeChild(tBody.firstChild);
    }

    // Re-add the newly sorted rows
    tBody.append(...sortedRows);

    // Remember how the column is currently sorted
    table.querySelectorAll("th").forEach(th => th.classList.remove("th-sort-asc", "th-sort-desc"));
    table.querySelector(`th:nth-child(${ column + 1})`).classList.toggle("th-sort-asc", asc);
    table.querySelector(`th:nth-child(${ column + 1})`).classList.toggle("th-sort-desc", !asc);
}

document.querySelectorAll(".table-sortable th").forEach(headerCell => {
    headerCell.addEventListener("click", () => {
        const tableElement = headerCell.parentElement.parentElement.parentElement;
        const headerIndex = Array.prototype.indexOf.call(headerCell.parentElement.children, headerCell);
        const currentIsAscending = headerCell.classList.contains("th-sort-asc");

        sortTableByColumn(tableElement, headerIndex, !currentIsAscending);
    });
});

const actionCreators = {
    findLiveTeamData: userActions.GetLiveTeamInfo,
    findTracks: userActions.GetTracks,
    getTeams: userActions.GetLiveTeamInfo,
    getResults: userActions.getJudgeResults
};

export default connect(mapState, actionCreators)(judgeDiscuss);