import React, { Component, Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';

import uploadIcon from '../../images/uploadWhite.png';
import deleteIcon from '../../images/deleteBlack.png';

import AddTables from './addTables';

class CreateAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            company: "",
            error: "",
        }
    }

    componentDidMount() {
        this.props.GetAdmin(this.props.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lookup.AdminData !== this.props.lookup.AdminData) {
            const adminData = this.props.lookup?.AdminData?.admin;
    
            if (adminData && Array.isArray(adminData) && adminData.length > 0) {
                this.setState({
                    email: adminData[0].email,
                    password: adminData[0].password,
                    company: adminData[0].company,
                });
            } else {
                console.error("Admin data is unavailable or invalid.");
            }
        }
    }

    validE(e) {
        const patt = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return patt.test(e);
    }

    validateEmail() {
        if (this.validE(this.state.email)) {
            return true;
        } else {
            return false;
        }
    }

    validatePassword() {
        if (this.state.password.length) {
            return true;
        } else {
            return false;
        }
    }

    validateCompany() {
        if (this.state.company.length) {
            return true;
        } else {
            return false;
        }
    }

    updateAdmin() {
        let { email, password, company } = this.state;
        if (this.validateEmail() || this.validatePassword() || this.validateCompany()) {
            this.props.UpdateAdmin(email, password, company, this.props.id);
            
        } else {
            this.setState({ error: "There is an issue with one or more of the values above. Make sure you have a valid email and there are no empty fields." });
        }

    }

    firstPage() {
            let { email, password, company, error } = this.state;
            return (
                <Fragment>
                    <center>
                        <div className='container'>
                            <form className='add-admin'>
                                <div>
                                    <input
                                        type="email" id="adminEmail" placeholder="Client Email" aria-labelledby="adminEmailHelp" className='smallInput'
                                        value={email}
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                    />
                                    <div id="adminEmailHelp" className="form-text eventTextMargin">
                                        Client Email
                                    </div>

                                    <br />

                                    <input
                                        type="password" id="passwordInput" placeholder="Password" aria-labelledby="passwordInputHelp" className='smallInput'
                                        value={password} maxLength="25"
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                    />
                                    <div id="passwordInputHelp" className="form-text eventTextMargin">
                                        Password
                                    </div>

                                    <br />

                                    <input
                                        type="text" id="companyName" placeholder="Company Name" aria-labelledby="companyNameHelp" className='smallInput'
                                        value={company} maxLength="25"
                                        onChange={(e) => this.setState({ company: e.target.value })}
                                    />
                                    <div id="companyNameHelp" className="form-text eventTextMargin">
                                        Company
                                    </div>

                                    <br />
                                    {error.length <= 0 ?
                                        <p className="">{error}</p>
                                        :
                                        <p className="warningText">{error}</p>
                                    }

                                    <br />
                                </div>
                            </form></div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-4'>
                                    <a href='/admin/accountdash'><button className='cancelButton'>Cancel</button></a>
                                </div>
                                <div className='col-4'>
                                    <button onClick={() => this.updateAdmin()} className='nextButton'>Update Admin</button>
                                </div>
                                <div className='col-2'></div>
                            </div>
                        </div><br />
                    </center>
                </Fragment>
            )
    }

    render() {
        return (
            <Fragment>
                {/* When adding a question the question pop up menu will appear here! */}
                <div className='centered'>
                    <div className="header">
                        <h1>Admin Creation Portal</h1>
                    </div>
                </div>
                {this.firstPage()}
            </Fragment>
        );
    }
}

function mapState(state) {
    const { session, lookup } = state;
    return { session, lookup };
}

const actionCreators = {
    GetAdmin: userActions.GetAdmin,
    UpdateAdmin: userActions.UpdateAdmin,
};

export default connect(mapState, actionCreators)(CreateAdmin);