import { userConstants } from "./constants";

const intialState = {
    Teams: [],
    Judges: [],
    Questions: [],
    Events: [],
    JudgeData: [],
    TeamData: [],
    ScoreData: null,
    MyTeamInfo: [],
    eventId: JSON.parse(localStorage.getItem('eventId')) || undefined,
    ResultsData: [],
    AdminData: [],
};

export function lookup(state = intialState, action) {
    switch (action.type) {
        case userConstants.LOGOUT:
            return intialState
        case userConstants.GETTEAMS:
            return {
                ...state,
                Teams: JSON.parse(action.teams)
            }
        case userConstants.CREATEEVENT:
            localStorage.setItem('eventId', JSON.stringify(action.eventId));
            return state;
        case userConstants.GETCOMMENTS:
            return {
                ...state,
                Comments: JSON.parse(action.comments)
            }
        case userConstants.GETJUDGES:
            return {
                ...state,
                Judges: JSON.parse(action.judges)
            }
        case userConstants.GETQUESTIONS:
            return {
                ...state,
                Questions: JSON.parse(action.questions)
            }
        case userConstants.GETEVENTS:
            return {
                ...state,
                Events: JSON.parse(action.events)
            }
        case userConstants.GETSCORES:
            return {
                ...state,
                ScoreData: JSON.parse(action.average)
            }
        case userConstants.GETLIVEJUDGEINFO:
            return {
                ...state,
                JudgeData: JSON.parse(action.judgeData)
            }
        case userConstants.GETLIVETEAMINFO:
            return {
                ...state,
                TeamData: JSON.parse(action.teamData)
            }
        case userConstants.GETMYTEAMINFO:
            return {
                ...state,
                MyTeamInfo: action.myTeamInfo
            }
        case userConstants.GETRESULTS:
            return {
                ...state,
                ResultsData: action.results
            }
        case userConstants.GETTRACKS:
            return{
                ...state,
                CompetitionTracks: JSON.parse(action.trackData)
            }
        case userConstants.GETADMINS:
            return{
                ...state,
                AdminData: JSON.parse(action.admins)
            }
        case userConstants.GETADMIN:
            return{
                    ...state,
                    AdminData: action.admin
                }
        default:
            return state;
    }
}