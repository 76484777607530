import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/index.css'
import { connect } from 'react-redux';
import { userActions } from '../../actions/useractions';

import editIcon from '../../images/editBlack.png';
import plusIcon from '../../images/editPlusBlack.png';
import searchButton from '../../images/searchBlack.png';


class Admin extends Component{
    constructor(props){
        super(props);
        this.state ={
            search: '', // State variable for the search term
            adminInfo: [],
            currentStatus: false,
            showModal: false,
            currentDelete: null,
        }

    }

    componentDidMount(){
       this.props.findAdmins();
    }

    addButton(){
        return(
            <Fragment>
                    <button className="overlayButton" onClick={() => userActions.reroute("admin/createadmin/")}>Create New Admin</button>
            </Fragment>
        )
    }

    admimPasswordBlock(password) {
        return (
            <Fragment>
                {!this.state.currentStatus ? 
                <div>                    
                    <input className="inputText" type='password' value={password}/>
                    <button className="blueButtonNormal" onClick={() => this.setState({currentStatus: true})}>Show</button>
                </div>
                :
                <div>                    
                    <input className="inputText" type='text' value={password}/>
                    <button className="blueButtonNormal" onClick={() => this.setState({currentStatus: false})}>Hide</button>
                </div>
                }

            </Fragment>

        )
    }

    edit(id) {
        //We are reusing the add page to edit as well
        userActions.reroute("admin/editadmin/" + id)
    }

    deletePopup(id) {
        this.setState({showModal: true});
        this.setState({currentDelete: id});
    }

    delete() {
        this.props.DeleteAccount(this.state.currentDelete);
    }

    updateEventSearch = (event) => {
        const searchValue = event.target.value;
        this.setState({
          search: searchValue,
        });

        const { AdminData } = this.props.lookup;
        const filteredEventInfo = AdminData.filter((element) => {
            
          return (
            element.email.toLowerCase().includes(searchValue.toLowerCase()) ||
            element.company.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
      
        // If the search term is empty, use the original JudgeData
        const adminInfo = searchValue ? filteredEventInfo : AdminData;
        //console.log(adminInfo);
        this.setState({
            adminInfo: adminInfo,
        });
    };

    adminAccountList() {
        const {AdminData} = this.props.lookup;
        console.log(this.state.adminInfo.length);
        const dataToRender = this.state.adminInfo.length > 0 ? this.state.adminInfo : AdminData;
        const accountsList = dataToRender.map(
            (element) => {
                return (
                    <tr>
                        <td>{element.email}</td>
                        <td>{element.company}</td>
                        <td>{this.admimPasswordBlock(element.password)}</td>
                        <td><button className="blueButtonNormal" onClick={() => this.edit(element.id)}>Edit</button></td>
                        <td><button className="blueButtonNormal" onClick={() => this.deletePopup(element.id)}>Delete</button></td>
                    </tr>
                )
            }
        )
        return (
            <Fragment>
                <div className="container">
                    <table className="tableView">
                        <tr>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Password</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        {accountsList}
                    </table>

                </div>
            </Fragment>
        )
    }

    deleteModal() {

            return(
                <Fragment>
                    <div className="container">
                        <h3 className="text-center">Are you sure you want to delete this account?</h3>
                        <div className="d-flex justify-content-center pt-2">
                            <button className="logout-btn me-4" onClick={() => this.delete()}>Yes</button>
                            <button className="logout-btn ms-4" onClick={() => this.setState({showModal: false})}>No</button>
                        </div>
                    </div>
                </Fragment>
            )
    }

    render(){
        const { search } = this.state;

        return(
            <Fragment>
                <div className="container d-flex">
                    <div className="ps-5">
                        <h1>Admin Account Dashboard</h1>
                    </div>
                    <div className="ps-3">
                        <button className="logout-btn" onClick={() => userActions.reroute("admin/dashboard/")}>Back to dashboard</button>
                    </div>
                </div>
                <div className="search-box rightSearchBoxAdmin">
                    <button className="btn-search"><img src={searchButton} alt='search button png'/></button>
                    <input type="text" className="input-search" placeholder="Type to Search..." value={search} onChange={this.updateEventSearch}/>
                </div>
                <br/>

                { this.state.showModal &&
                    <div className="modal-delete">
                        <button onClick={() => this.setState({showModal: false})}>X</button>
                        {this.deleteModal()}
                    </div>                      
                }

                {this.adminAccountList()}
                {this.addButton()}                

            </Fragment>
        );
    }
 }

 function mapState(state) {
    const {session, lookup} = state;
    return {session, lookup};
}

const actionCreators = {
    findAdmins: userActions.GetAdmins,
    DeleteAccount: userActions.DeleteAdmin,
};

export default connect(mapState, actionCreators)(Admin);